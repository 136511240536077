export class ServiceRouter {
    get config() {
        return {"catalog":"www.bookelevent.com","event-manager":"events.bookelevent.com","host-portal":"hosts.bookelevent.com","oms":"oms.bookelevent.com","checkout":"checkout.bookelevent.com","storefront-catalog":"{storefront}.bookelevent.com","storefront-checkout":"{storefront}-checkout.bookelevent.com","cookie_domain":"bookelevent.com"};
    }

    host(service) {
        if (!this.config[service]) {
            throw new Error('Unknown service: ' + service);
        }
        return this.config[service];
    }

    storefrontHost(service, storefront) {
        const host = this.host(service);
        return host.replace('{storefront}', storefront);
    }

    url(service, path = '', queryParams = {}, secure = true) {
        const host = this.host(service);
        if (!host) {
            throw new Error('Unknown service: ' + service);
        }
        const protocol = secure ? 'https' : 'http';
        const url = new URL(path, protocol + '://' + host);
        for (const [key, value] of Object.entries(queryParams)) {
            url.searchParams.append(key, value);
        }
        return url.toString();
    }

    storefrontUrl(
        service,
        storefront,
        path = '',
        queryParams = {},
        secure = true
    ) {
        const host = this.storefrontHost(service, storefront);
        const url = new URL(path, (secure ? 'https' : 'http') + '://' + host);
        for (const [key, value] of Object.entries(queryParams)) {
            url.searchParams.append(key, value);
        }
        return url.toString();
    }

    catalogHost() {
        return this.host('catalog');
    }
    
    catalogUrl(path = '', queryParams = {}, secure = true) {
        return this.url('catalog', path, queryParams, secure);
    }

    storefrontCatalogHost(storefront) {
        if (storefront === 'www') {
            return this.host('catalog');
        }
        return this.storefrontHost('storefront-catalog', storefront);
    }
    
    storefrontCatalogUrl(
        storefront,
        path = '',
        queryParams = {},
        secure = true
    ) {
        if (storefront === 'www') {
            return this.catalogUrl(path, queryParams, secure);
        }
        return this.storefrontUrl('storefront-catalog', storefront, path, queryParams, secure);
    }

    checkoutHost() {
        return this.host('checkout');
    }
    
    checkoutUrl(path = '', queryParams = {}, secure = true) {
        return this.url('checkout', path, queryParams, secure);
    }

    storefrontCheckoutHost(storefront) {
        if (storefront === 'www') {
            return this.host('checkout');
        }
        return this.storefrontHost('storefront-checkout', storefront);
    }
    
    storefrontCheckoutUrl(
        storefront,
        path = '',
        queryParams = {},
        secure = true
    ) {
        if (storefront === 'www') {
            return this.checkoutUrl(path, queryParams, secure);
        }
        return this.storefrontUrl('storefront-checkout', storefront, path, queryParams, secure);
    }

    eventManagerHost() {
        return this.host('event-manager');
    }
    
    eventManagerUrl(path = '', queryParams = {}, secure = true) {
        return this.url('event-manager', path, queryParams, secure);
    }

    hostPortalHost() {
        return this.host('host-portal');
    }
    
    hostPortalUrl(path = '', queryParams = {}, secure = true) {
        return this.url('host-portal', path, queryParams, secure);
    }
}